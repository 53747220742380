import {
    SET_TIME_TICKER,
    SUB_TIME_TICKER,
    SET_GAME_TICKER_LIMIT,
    SET_PAIR
} from "@actions/actionTypes";
import initialStore from "../initialStore";

export function subscribeTimeTicker() {
    return dispatch => {
        dispatch({
            eventOn: SUB_TIME_TICKER,
            handle: (data) => {
                dispatch({type: SET_TIME_TICKER, ticker: data});
            }
        })
    }
}


export function disabledBetsAreas() {
    return dispatch => {
        dispatch({type: SET_PAIR, isEnable: false})
    }
}

export function resetTimeTicker() {
    return dispatch => {
        dispatch({
            type: SET_TIME_TICKER,
            ticker:  {
                Start: initialStore.timeTicker.start,
                End: initialStore.timeTicker.end
            }
        })
    }
}