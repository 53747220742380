// export const DefaultLanguage = 'en-US';
export const DefaultLanguage = 'en';
export const DefaultLanguageCode = 42;

export const Language = {
    ENGLISH: 1,
    RUSSIAN: 2
};

export const LanguageName = {
    1: "English",
    2: "Russian"
};

export const LanguageCode = {
    1: "EN",
    2: "RU"
};

export const LanguageForApi = {
    "EN": 1,
    "RU": 2,
    "AM": 3
};

export const LanguageCodesArr = [
    "EN",
    "RU"
];


export const BetsType = {
    1: "win",
    2: "lose",
    3: "refund",
    "win": 1,
    "lose": 2,
    "refund": 3
}
export const TimeTicker = {
    6: "yellow",
    3: "red",
    0: "white"
}


export const SocketUpdateType = {
    0: "Ping",
    1: "Error",
    2: "RoundStart",
    3: "Card",
    4: "Odd",
    5: "Timer",
    6: "Result",
    7: "Bet",
    8: "Pair"
}
export const CardsSuit = {
    0: "None", // for joker
    1: "clubs",
    2: "diamonds",
    3: "hearts",
    4: "spades"
}

export const PlayerOddType = {
    0: "None",
    1: "PlayerA",
    2: "PlayerB",
    3: "Both",
    "None" : 0,
    "PlayerA": 1,
    "PlayerB": 2,
    "Both": 3
}

export const Prediction = {
    _7Up: 0,
    _7: 1,
    _7Down: 2,
    Red: 3,
    Black: 4,
    Odd: 5,
    Even: 6,
    0:"_7Up",
    1:"_7",
    2:"_7Down",
    3:"Red",
    4:"Black",
    5:"Odd",
    6:"Even"
}

export const gameHistory = {
    0: "U", //UP
    1: "_7", //DOWN
    2: "D", // 7
    "U": 0,
    "_7": 1,
    "D": 2
}

export const gameHistoryClassis = {
    0: "U", //UP
    1: "7", //DOWN
    2: "D" , // 7
    "U": 0,
    "_7": 1,
    "D": 2
}
// public enum Main
// {
//     _7Up,
//     _7,
//     _7Down
// }

// public enum SideBet
// {
//     Red,
//     Black,
//     Odd,
//     Even
// }

export const CardSide = {
    0: "right",
    1: "center",
    2: "left"
}

export const CardName = [
    "2", "3", "4", "5", "6", "7", "8", "9", "10", "J", "Q", "K", "A"
]

export const CardTypeMap = {
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    9: "9",
    10: "10",
    11: "J",
    12: "Q",
    13: "K",
    14: "A",
    15: "Joker1",
    16: "Joker2"
}

export const CardType = {
    "2": 2,
    "3": 3,
    "4": 4,
    "5": 5,
    "6": 6,
    "7": 7,
    "8": 8,
    "9": 9,
    "10": 10,
    "J": 11,
    "Q": 12,
    "K": 13,
    "A": 1,
    "Joker1": 15,
    "Joker2": 16
}

export const SocketReadyState = {
    "CONNECTING": 0,
    "OPEN": 1,
    "CLOSING": 2,
    "CLOSED": 3
}